import { Modal } from '@redreamerlab/uikit';
import {
  StyledCheckButton,
  StyledLabel,
  StyledRow,
  StyledTitle,
  StyledValue,
  StyledWrapper,
} from './Styles';
import { ShippingInfo } from '../type';

type Props = {
  shippingInfo: ShippingInfo;
  assetId: number;
  onClose: () => void;
};

const ShippingIformation = ({ shippingInfo, assetId, onClose }: Props) => {
  return (
    <Modal
      hasBackDrop
      backgroundColor="#ffffff"
      closeColor="#ffffff"
      width={600}
      opened={assetId > -1}
      onClose={onClose}
    >
      <StyledWrapper>
        <StyledTitle>Shipping Information</StyledTitle>
        <StyledRow>
          <StyledLabel>Name</StyledLabel>
          <StyledValue>
            {shippingInfo.firstName}
            {shippingInfo.lastName}
          </StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Email</StyledLabel>
          <StyledValue>{shippingInfo.email}</StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Phone</StyledLabel>
          <StyledValue>{shippingInfo.phone}</StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Address</StyledLabel>
          <StyledValue>{shippingInfo.address}</StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel>City</StyledLabel>
          <StyledValue>{shippingInfo.city}</StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Post Code</StyledLabel>
          <StyledValue>{shippingInfo.postCode}</StyledValue>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Country</StyledLabel>
          <StyledValue>{shippingInfo.country}</StyledValue>
        </StyledRow>
        <StyledCheckButton onClick={onClose}>OK</StyledCheckButton>
      </StyledWrapper>
    </Modal>
  );
};

export default ShippingIformation;
