export enum StatusType {
  Opened = 'redeem',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Released = 'released',
}

export interface Redemption {
  redeemer: string;
  createdAtGlobalSequence: number;
  createdAtBlockNum: number;
  createdAt: string;
  updatedAtGlobalSequence: number;
  updatedAtBlockNum: number;
  updatedAt: string;
  status: StatusType;
  transactionId: string;
  asset: {
    assetId: number;
    templateId: number;
    schema: string;
    data: {
      img: string;
      name: string;
      redemption_template: number;
      redemption_type: number;
    };
    collection: {
      displayName: string;
      name: string;
      image: string;
    };
  };
}

export interface ShippingInfo {
  firstName: string;
  lastName: string;
  country: string;
  address: string;
  phone: string;
  city: string;
  postCode: string;
  additionalAddress: string;
  email: string;
  status: string;
}
