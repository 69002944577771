import styled from 'styled-components';
import Button from '@mui/material/Button';
import { Row, size } from '../../../styles/common';
import { Modal } from '@redreamerlab/uikit';

const StyledCancelButton = styled(Button)`
  &&& {
    ${size('178px', '40px')};
    padding: 8px 0;
    background: #c3cfd9;
    color: black;
    border-radius: 4px;
    font-weight: 700;
    text-transform: none;
    margin-top: 40px;
    
    :hover {
      background: #e3e3e3;
    },
  }
`;

const StyledWrapper = styled(Row)`
  padding: 30px 0;
  justify-content: center;
`;

type Props = {
  isOpen: boolean;
  errorMessage: string;
  onClose: () => void;
};

const ResponseModal = ({ isOpen, errorMessage, onClose }: Props) => {
  return (
    <Modal
      hasBackDrop
      backgroundColor="#ffffff"
      closeColor="#ffffff"
      width={400}
      opened={isOpen}
      onClose={onClose}
    >
      <StyledWrapper>
        <Row direction="column" justify="center">
          <div>ERROR: {errorMessage}</div>
          <StyledCancelButton onClick={onClose}>OK</StyledCancelButton>
        </Row>
      </StyledWrapper>
    </Modal>
  );
};

export default ResponseModal;
