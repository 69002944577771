import { useWaxCloudWalletContext } from '@redreamerlab/uikit';
import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

interface ContextType {}

const ApplicationContext = createContext<ContextType>({});

const Provider = ({ children }: Props) => {
  const contexts = {};

  const history = useHistory();

  const { waxUser } = useWaxCloudWalletContext();

  useEffect(() => {
    if (!waxUser) {
      history.push('/');
    }
  }, [waxUser, history]);

  return <ApplicationContext.Provider value={contexts}>{children}</ApplicationContext.Provider>;
};

const useApplicationContext = () => useContext(ApplicationContext);

const ApplicationProvider = Provider;

export { ApplicationProvider };

export default useApplicationContext;
