import useOnClickOutside from 'use-onclickoutside';
import {
  StyledWrapper,
  StyledTitle,
  StyledStatus,
  StyledConfigWrapper,
  StyledDownloadButton,
  StyledCountryWrapper,
  StyledStatusWrapper,
  StyledOptionsWrapper,
  StyledChecked,
  StyledCheckedWrapper,
  StyledDivide,
  StyledStatusOpWrapper,
  StyledClickSpace,
} from './Styles';
import filterIcon from '../../assets/filter.svg';
import downloadIcon from '../../assets/download.svg';
import downIcon from '../../assets/down.svg';
import { Row } from '../../styles/common';
import { useEffect, useRef, useState } from 'react';
import ShippingInformation from './ShippingInformation';
import { Redemption, ShippingInfo } from './type';
import axios from 'axios';
import humps from 'humps';
import { useParams } from 'react-router-dom';
import { Loading } from '@redreamerlab/uikit';
import moment from 'moment';
import ResponseModal from './ResponseModal';
import csvDownload from 'json-to-csv-export';
import RedemptionTable from './RedemptionTable';

const countries = [
  {
    label: 'Australia',
    value: 'Australia',
  },
  {
    label: 'Japan',
    value: 'Japan',
  },
  {
    label: 'USA',
    value: 'USA',
  },
];

const statusList = [
  {
    label: 'Open',
    value: 'redeem',
  },
  {
    label: 'Accepted',
    value: 'accepted',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Released',
    value: 'released',
  },
];

const initialCoutries = countries.map((item) => item.value);
const initialStatus = statusList.map((item) => item.value);
const initialShippingInfo = {
  firstName: '',
  lastName: '',
  country: '',
  address: '',
  phone: '',
  city: '',
  postCode: '',
  additionalAddress: '',
  email: '',
  status: '',
};

const RedemptionsPage = () => {
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [shippingInfoAssetId, setShippingInfoAssetId] = useState<number>(-1);
  const [checkedCountries, setCheckedCountries] = useState<string[]>(initialCoutries);
  const [checkedStatus, setCheckedStatus] = useState<string[]>(initialStatus);
  const [redemptions, setRedemptions] = useState<Redemption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [actionError, setActionError] = useState<string>('');
  const [shippingInfo, setShippingInfo] = useState<Map<number, ShippingInfo>>(new Map());

  const { collectionId } = useParams<{ collectionId: string }>();

  const contriesRef = useRef<HTMLDivElement | null>(null);
  const statusRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(contriesRef, () => {
    setShowCountries(false);
  });

  useOnClickOutside(statusRef, () => {
    setShowStatus(false);
  });

  const handleCheckAllCountries = (checked: boolean) => {
    setCheckedCountries(checked ? initialCoutries : []);
  };

  const handleCheckAllStatus = (checked: boolean) => {
    setCheckedStatus(checked ? initialStatus : []);
  };

  const handleCheckCountries = (country: string) => {
    const index = checkedCountries.indexOf(country);
    if (index !== -1) {
      setCheckedCountries(checkedCountries.filter((item, idx) => idx !== index));
    } else {
      setCheckedCountries([...checkedCountries, country]);
    }
  };

  const handleCheckStatus = (status: string) => {
    const index = checkedStatus.indexOf(status);
    if (index !== -1) {
      setCheckedStatus(checkedStatus.filter((item, idx) => idx !== index));
    } else {
      setCheckedStatus([...checkedStatus, status]);
    }
  };

  const handleDownloadCSV = () => {
    const formatData = redemptions
      .filter((item) =>
        checkedCountries.includes(shippingInfo.get(item.asset.assetId)?.country || ''),
      )
      .filter((item) => checkedStatus.includes(item.status))
      .map((item) => ({
        NFT: item.asset.collection.displayName,
        Name: item.redeemer,
        Email: shippingInfo.get(item.asset.assetId)?.email || '',
        Phone_No: shippingInfo.get(item.asset.assetId)?.phone || '',
        Address: shippingInfo.get(item.asset.assetId)?.address || '',
        City: shippingInfo.get(item.asset.assetId)?.city || '',
        Post_code: shippingInfo.get(item.asset.assetId)?.postCode || '',
        Country: shippingInfo.get(item.asset.assetId)?.country || '',
        Creates_date: moment(item.createdAt).format('MMM DD, YYYY HH:mm:ss'),
        Updated_date: moment(item.updatedAt).format('MMM DD, YYYY HH:mm:ss'),
        Status: item.status,
      }));

    const dataToConvert = {
      data: formatData,
      filename: `redemptions_${moment().format('YYYYMMDD')}`,
      delimiter: ',',
      headers: [
        'NFT',
        'Name',
        'Email',
        'Phone_No',
        'Address',
        'City',
        'Post_code',
        'Country',
        'Creates_date',
        'Updated_date',
        'Status',
      ],
    };
    csvDownload(dataToConvert);
  };

  useEffect(() => {
    const fetchShippingInfo = (params: string) => {
      axios.get(`/shippingInfo?${params}`).then((res) => {
        const map = new Map();
        const data = res.data.shippingInfo;
        Object.keys(res.data.shippingInfo).forEach((id) => {
          map.set(
            Number(id),
            data[id] === 'asset_id does not exist.' ? initialShippingInfo : data[id],
          );
        });
        setShippingInfo(map);
      });
    };

    const fetchRedemptions = () => {
      setIsLoading(true);
      axios
        .get(`https://api.nfthive.io/api/redemptions/${collectionId}`)
        .then(async (res) => {
          const transform = humps.camelizeKeys(res.data) as Redemption[];
          setRedemptions(transform);

          const assetIds = transform.map((item) => `asset_id=${item.asset.assetId}`);
          const params = assetIds.join('&');
          await fetchShippingInfo(params);
        })
        .finally(() => setIsLoading(false));
    };

    fetchRedemptions();
  }, [collectionId, refreshCount]);

  return (
    <StyledWrapper>
      <Loading isLoading={isLoading} />
      <StyledTitle>Redemptions</StyledTitle>
      <StyledConfigWrapper>
        <Row>
          <img src={filterIcon} alt="filter" />
          <StyledCountryWrapper ref={contriesRef}>
            Country
            <img src={downIcon} alt="down" />
            <StyledClickSpace onClick={() => setShowCountries(!showCountries)} />
            {showCountries && (
              <StyledOptionsWrapper>
                <StyledCheckedWrapper>
                  <StyledChecked
                    type="checkbox"
                    checked={checkedCountries.length === countries.length}
                    onChange={(e) => handleCheckAllCountries(e.target.checked)}
                  />
                  All
                </StyledCheckedWrapper>
                <StyledDivide />
                {countries.map((item) => (
                  <>
                    <StyledCheckedWrapper>
                      <StyledChecked
                        type="checkbox"
                        checked={checkedCountries.includes(item.value)}
                        onChange={() => handleCheckCountries(item.value)}
                      />
                      {item.label}
                    </StyledCheckedWrapper>
                  </>
                ))}
              </StyledOptionsWrapper>
            )}
          </StyledCountryWrapper>
          <StyledStatusWrapper ref={statusRef}>
            Status
            <img src={downIcon} alt="down" />
            <StyledClickSpace onClick={() => setShowStatus(!showStatus)} />
            {showStatus && (
              <StyledStatusOpWrapper>
                <StyledCheckedWrapper>
                  <StyledChecked
                    type="checkbox"
                    checked={checkedStatus.length === statusList.length}
                    onChange={(e) => handleCheckAllStatus(e.target.checked)}
                  />
                  All
                </StyledCheckedWrapper>
                <StyledDivide />
                {statusList.map((item) => (
                  <>
                    <StyledCheckedWrapper>
                      <StyledChecked
                        type="checkbox"
                        checked={checkedStatus.includes(item.value)}
                        onChange={() => handleCheckStatus(item.value)}
                      />
                      <StyledStatus status={item.value}>{item.label}</StyledStatus>
                    </StyledCheckedWrapper>
                  </>
                ))}
              </StyledStatusOpWrapper>
            )}
          </StyledStatusWrapper>
        </Row>
        <Row>
          <StyledDownloadButton onClick={handleDownloadCSV}>
            <img src={downloadIcon} alt="download" />
            Download
          </StyledDownloadButton>
        </Row>
      </StyledConfigWrapper>
      <RedemptionTable
        collectionId={collectionId}
        redemptions={redemptions}
        checkedStatus={checkedStatus}
        checkedCountries={checkedCountries}
        shippingInfo={shippingInfo}
        onRefresh={() => setRefreshCount((prev) => prev + 1)}
        setActionError={(msg) => setActionError(msg)}
        setShippingAssetId={(id) => setShippingInfoAssetId(id)}
      />
      <ShippingInformation
        shippingInfo={shippingInfo.get(shippingInfoAssetId) || initialShippingInfo}
        assetId={shippingInfoAssetId}
        onClose={() => setShippingInfoAssetId(-1)}
      />
      <ResponseModal
        isOpen={!!actionError}
        errorMessage={actionError}
        onClose={() => setActionError('')}
      />
    </StyledWrapper>
  );
};

export default RedemptionsPage;
