import Home from '../pages/Home';
import CollectionsPage from '../pages/CollectionsPage';
import RedemptionsPage from '../pages/RedemptionsPage';

const routes = [
  {
    id: 'home',
    name: 'Home',
    path: '/',
    component: (props: any) => <Home {...props} />,
  },
  {
    id: 'collections',
    name: 'Collections',
    path: '/collections',
    component: (props: any) => <CollectionsPage {...props} />,
  },
  {
    id: 'redemptions',
    name: 'Redemptions',
    path: '/collections/:collectionId/redemptions',
    component: (props: any) => <RedemptionsPage {...props} />,
  },
];

export default routes;
