import styled from 'styled-components';
import { Row } from '../../styles/common';
import { Link } from 'react-router-dom';

export const StyledWrapper = styled.div`
  width: 100%;
  border-bottom: 2px solid #c3cfd9;
  padding: 16px 0;
`;

export const StyledContent = styled(Row)`
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledLogo = styled.img`
  height: 37px;
  cursor: pointer;
  margin-right: 28px;
`;

export const StyledNFTHive = styled.img`
  width: 94px;
`;

export const StyledUnbindContainer = styled.div`
  & > div > div {
    color: #b143ff;
  }
`;

export const StyledCollectionText = styled(Link)`
  color: #bd34d1;
  font-weight: bold;
  margin-right: 16px;
  text-decoration: none;
`;
