import styled from 'styled-components';
import { fontEllipsis, fontStyle } from '../../styles/common';

export const StyledWrapper = styled.div`
  padding: 28px 0;
  max-width: 1120px;
  margin: 0 auto;
`;

export const StyledTitle = styled.div`
  font-family: Poppins;
  font-style: italic;
  ${fontStyle('28px', 'bold')};
  text-align: center;
`;

export const StyledGrid = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(5, 200px);
  gap: 30px;

  &&& {
    button {
      margin-bottom: 0;
    }
  }
`;

export const StyledCardTitle = styled.div`
  word-break: break-all;
  ${fontEllipsis(2)};
  ${fontStyle('16px', '700')};
  color: ${({ theme }) => theme.colors.darkDream};
  text-align: center;
  padding-bottom: 16px;
`;
