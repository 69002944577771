import styled from 'styled-components';
import Button from '@mui/material/Button';
import { Row, fontStyle, size } from '../../../styles/common';

export const StyledWrapper = styled.div`
  padding: 20px 60px;
  background: #fff;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

export const StyledTitle = styled.div`
  font-family: Poppins;
  font-style: italic;
  ${fontStyle('28px', 'bold')};
  margin-bottom: 64px;
  text-align: center;
`;

export const StyledLabel = styled.div`
  margin-right: 36px;
  text-align: right;
  width: 100px;
`;

export const StyledValue = styled.div`
  color: #7f8e9c;
  word-break: break-all;
  flex: 1;
`;

export const StyledCheckButton = styled(Button)`
&&& {
  ${size('100%', '40px')};
  padding: 8px 0;
  background: #c3cfd9;
  color: black;
  border-radius: 4px;
  font-weight: 700;
  text-transform: none;
  margin-top: 24px;
  
  :hover {
    background: #e2e2e2;
  },
}
`;
