import styled from 'styled-components';
import { fontStyle, size } from '../../../styles/common';
import Button from '@mui/material/Button';

export const StyledCollectionText = styled.div`
  color: #bd34d1;
  ${fontStyle('14px', '700')};
  text-align: center;
`;

export const StyledApproveButton = styled(Button)`
  &&& {
    ${size('106px', '36px')};
    padding: 8px 0;
    background: #bd34d1;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 700;
    text-transform: none;
    margin-right: 16px;

    :hover {
      background: #ca74d6;
    }
  }
`;

export const StyledRejectButton = styled(Button)`
  &&& {
    ${size('106px', '36px')};
    padding: 8px 0;
    background: #f7c325;
    color: black;
    border-radius: 4px;
    font-weight: 700;
    text-transform: none;

    :hover {
      background: #fadf8e;
    }
  }
`;
