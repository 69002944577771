import {
  CollectionCard,
  ConnectButton,
  Loading,
  useWaxCloudWalletContext,
} from '@redreamerlab/uikit';
import { StyledCardTitle, StyledGrid, StyledTitle, StyledWrapper } from './Styles';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Collection } from './type';
import humps from 'humps';
import axios from 'axios';

const CollectionsPage = () => {
  const { waxUser } = useWaxCloudWalletContext();

  const [collections, setCollections] = useState<Collection[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    if (waxUser?.account) {
      setIsLoading(true);
      axios
        .get(
          `https://wax.api.atomicassets.io/atomicassets/v1/collections?authorized_account=${waxUser.account}&page=1&limit=100`,
        )
        .then((res) => {
          const transform = humps.camelizeKeys(res.data.data);
          setCollections(transform as Collection[]);
        })
        .finally(() => setIsLoading(false));
    }
  }, [waxUser]);

  return (
    <StyledWrapper>
      <Loading isLoading={isLoading} />
      <StyledTitle>Collections</StyledTitle>
      <StyledGrid>
        {collections.map((item) => (
          <CollectionCard
            key={item.collectionName}
            imgUrlTransform={`https://ipfs.hivebp.io/preview?collection=${item.collectionName}&hash=${item.img}&size=320`}
            handleRedeem={() => {}}
          >
            <StyledCardTitle>{item.name}</StyledCardTitle>
            <ConnectButton
              name="Manage"
              onClick={() => history.push(`/collections/${item.collectionName}/redemptions`)}
            />
          </CollectionCard>
        ))}
      </StyledGrid>
    </StyledWrapper>
  );
};

export default CollectionsPage;
