import styled from 'styled-components';
import { fontStyle } from '../../styles/common';

export const StyledWrapper = styled.div`
  padding-top: 8%;
`;

export const StyledItalicTitle = styled.div`
  ${fontStyle('32px')};
  font-style: italic;
  font-family: Poppins;
  text-align: center;
`;

export const StyledBoldTitle = styled(StyledItalicTitle)`
  ${fontStyle('36px', 'bold')};
`;

export const StyledButtonContainer = styled.div`
  width: 230px;
  padding: 16px;
  margin: 0 auto;
  margin-top: 100px;
`;
