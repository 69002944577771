import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Navbar from './components/Navbar';
import Router from './routes';
import theme from './styles/theme';
import { ApplicationProvider } from './context/applicationContext';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ApplicationProvider>
          <Navbar />
          <Router />
        </ApplicationProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
