import listIcon from '../../../assets/list.svg';
import checkIcon from '../../../assets/check.svg';
import rejectIcon from '../../../assets/close.svg';
import { Redemption, ShippingInfo, StatusType } from '../type';
import { StyledApproveButton, StyledCollectionText, StyledRejectButton } from './Styles';
import { StyledStatus } from '../Styles';
import moment from 'moment';
import { useWaxCloudWalletContext } from '@redreamerlab/uikit';

type Props = {
  collectionId: string;
  redemptions: Redemption[];
  shippingInfo: Map<number, ShippingInfo>;
  checkedStatus: string[];
  checkedCountries: string[];
  onRefresh: () => void;
  setActionError: (errorMsg: string) => void;
  setShippingAssetId: (assetId: number) => void;
};

const capitalize = (s: string) => {
  return s && s[0].toUpperCase() + s.slice(1);
};

const RedemptionTable = ({
  collectionId,
  redemptions,
  shippingInfo,
  checkedStatus,
  checkedCountries,
  onRefresh,
  setActionError,
  setShippingAssetId,
}: Props) => {
  const { wax, waxUser } = useWaxCloudWalletContext();

  const handleAccept = async (assetId: number) => {
    if (!wax?.api) return null;

    try {
      const rawTx = {
        actions: [
          {
            account: 'redeemprtcol',
            name: 'accept',
            authorization: [
              {
                actor: waxUser!.account,
                permission: 'active',
              },
            ],
            data: {
              authorized_account: waxUser!.account,
              collection_name: collectionId,
              asset_id: assetId,
            },
          },
        ],
      };

      const option = {
        blocksBehind: 3,
        expireSeconds: 30,
        broadcast: true,
        sign: true,
      };

      await wax.api.transact(rawTx, option);
      onRefresh();
    } catch (error) {
      setActionError((error as Error).message);
    }
  };

  const handleReject = async (assetId: number) => {
    if (!wax?.api) return null;

    try {
      const rawTx = {
        actions: [
          {
            account: 'redeemprtcol',
            name: 'reject',
            authorization: [
              {
                actor: waxUser!.account,
                permission: 'active',
              },
            ],
            data: {
              authorized_account: waxUser!.account,
              collection_name: collectionId,
              asset_id: assetId,
              memo: 'reject',
            },
          },
        ],
      };

      const option = {
        blocksBehind: 3,
        expireSeconds: 30,
        broadcast: true,
        sign: true,
      };

      await wax.api.transact(rawTx, option);
      onRefresh();
    } catch (error) {
      setActionError((error as Error).message);
    }
  };

  const handleRelease = async (assetId: number) => {
    if (!wax?.api) return null;

    try {
      const rawTx = {
        actions: [
          {
            account: 'redeemprtcol',
            name: 'release',
            authorization: [
              {
                actor: waxUser!.account,
                permission: 'active',
              },
            ],
            data: {
              authorized_account: waxUser!.account,
              collection_name: collectionId,
              asset_id: assetId,
            },
          },
        ],
      };

      const option = {
        blocksBehind: 3,
        expireSeconds: 30,
        broadcast: true,
        sign: true,
      };

      await wax.api.transact(rawTx, option);
      onRefresh();
    } catch (error) {
      setActionError((error as Error).message);
    }
  };

  return (
    <table cellSpacing="0" cellPadding="0">
      <thead>
        <tr>
          <th style={{ width: 300 }}></th>
          <th>Name</th>
          <th>City</th>
          <th>Country</th>
          <th style={{ width: 150 }}>Created date</th>
          <th style={{ width: 150 }}>Updated date</th>
          <th>Status</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {redemptions
          .filter((item) =>
            checkedCountries.includes(shippingInfo.get(item.asset.assetId)?.country || ''),
          )
          .filter((item) => checkedStatus.includes(item.status))
          .map((item) => (
            <tr key={item.asset.assetId}>
              <td style={{ width: 300 }}>
                <StyledCollectionText>{item.asset.collection.displayName}</StyledCollectionText>
              </td>
              <td>{item.redeemer}</td>
              <td>{shippingInfo.get(item.asset.assetId)?.city || ''}</td>
              <td>{shippingInfo.get(item.asset.assetId)?.country || ''}</td>
              <td style={{ width: 150 }}>
                {moment(item.createdAt).format('MMM DD, YYYY HH:mm:ss')}
              </td>
              <td style={{ width: 150 }}>
                {moment(item.updatedAt).format('MMM DD, YYYY HH:mm:ss')}
              </td>
              <td>
                <StyledStatus status={item.status}>
                  {capitalize(item.status === 'redeem' ? 'open' : item.status)}
                </StyledStatus>
              </td>
              <td>
                <img
                  src={listIcon}
                  alt="shipping information"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShippingAssetId(item.asset.assetId)}
                />
              </td>
              <td>
                {item.status === StatusType.Opened && (
                  <>
                    <StyledApproveButton onClick={() => handleAccept(item.asset.assetId)}>
                      <img src={checkIcon} alt="check" />
                      Accept
                    </StyledApproveButton>
                    <StyledRejectButton onClick={() => handleReject(item.asset.assetId)}>
                      <img src={rejectIcon} alt="reject" />
                      Reject
                    </StyledRejectButton>
                  </>
                )}
                {item.status === StatusType.Accepted && (
                  <StyledApproveButton onClick={() => handleRelease(item.asset.assetId)}>
                    <img src={checkIcon} alt="check" />
                    Release
                  </StyledApproveButton>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default RedemptionTable;
