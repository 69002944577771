import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import { Row, fontStyle, size } from '../../styles/common';

const ContentMargin = css`
  max-width: 1120px;
  margin: 0 auto;
`;

export const StyledWrapper = styled.div`
  padding: 28px 0;

  table {
    width: 100%;
    empty-cells: show;
  }

  th {
    padding: 6px 0;
    background: #f2f5f7;
    text-align: left;
  }

  td {
    padding: 25px 0;
    text-align: left;
  }
`;

export const StyledTitle = styled.div`
  font-family: Poppins;
  font-style: italic;
  ${fontStyle('28px', 'bold')};
  text-align: left;
  margin-bottom: 28px;
  ${ContentMargin}
`;

export const StyledStatus = styled.div<{ status: string }>`
  background: ${({ status }) => {
    switch (status) {
      case 'redeem':
        return '#F7C325';
      case 'accepted':
        return '#E8833A';
      case 'rejected':
        return '#D3455B';
      case 'released':
        return '#25A7A7';
      default:
        return '#ffffff';
    }
  }};
  color: ${({ status }) => {
    switch (status) {
      case 'accepted':
      case 'rejected':
      case 'released':
        return '#ffffff';
      case 'open':
      default:
        return '#000000';
    }
  }};
  border-radius: 60px;
  padding: 2px 10px;
  display: inline;
  font-weight: 700;
`;

export const StyledDownloadButton = styled(Button)`
  &&& {
    ${size('130px', '36px')};
    padding: 6px 0;
    border: solid 1px #bd34d1;
    color: #bd34d1;
    border-radius: 4px;
    font-weight: 700;
    text-transform: none;
  }
`;

export const StyledConfigWrapper = styled(Row)`
  ${ContentMargin};
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 24px;
`;

export const StyledCountryWrapper = styled(Row)`
  padding: 6px 16px;
  border-radius: 4px;
  ${fontStyle('15px', '700')};
  margin-right: 16px;
  margin-left: 10px;
  border: solid 1px #000;
  cursor: pointer;
  position: relative;
`;

export const StyledStatusWrapper = styled(StyledCountryWrapper)`
  margin-right: 0;
  margin-left: 0;
`;

export const StyledOptionsWrapper = styled.div`
  padding: 10px 16px 2px 16px;
  border: 1px solid #000;
  border-radius: 4px;
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  width: 100%;
  background-color: #fff;
  width: 131px;
`;

export const StyledStatusOpWrapper = styled(StyledOptionsWrapper)`
  width: 149px;
`;

export const StyledCheckedWrapper = styled(Row)`
  padding-bottom: 8px;
`;

export const StyledDivide = styled.div`
  height: 1px;
  width: 100%;
  background: #000;
  margin-bottom: 8px;
`;

export const StyledChecked = styled.input`
  position: relative;
  cursor: pointer;
  margin: 0;
  margin-right: 12px;
  accent-color: #fff;
  ${size('18px', '18px')};

  :before {
    content: '';
    display: block;
    position: absolute;
    ${size('18px', '18px')};
    top: 0;
    left: 0;
    background-color: #fff;
    border: solid 1px #bd34d1;
    border-radius: 4px;
  }
  :checked:before {
    content: '';
    display: block;
    position: absolute;
    ${size('18px', '18px')};
    top: 0;
    left: 0;
    background-color: #bd34d1;
  }
  :checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
  }
`;

export const StyledClickSpace = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
